<template>
  <div>
    <v-container>
      <p style="font-style: italic">
        When 0.501 g of sucrose (table sugar, white microcrystalline solid) is dissolved in water
        (clear colorless liquid) to create a solution in a 10 mL volumetric flask, a clear colorless
        solution is created.
      </p>

      <p>What in the statement above is:</p>

      <p>
        a) quantitative data?
        <v-select
          v-model="inputs.question1Value"
          :items="optionsQ1"
          class="d-sm-inline-block ml-3 mr-3"
          style="width: 300px"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
          <template #selection="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
        </v-select>
      </p>

      <p>
        b) qualitative data? &nbsp;
        <v-select
          v-model="inputs.question2Value"
          :items="optionsQ2"
          class="d-sm-inline-block ml-3 mr-3"
          style="width: 300px"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
          <template #selection="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
        </v-select>
      </p>

      <p>
        c) How many significant figures are present in the mass? &nbsp;&nbsp;&nbsp;
        <v-select
          v-model="inputs.question3Value"
          :items="optionsQ3"
          class="d-sm-inline-block ml-3 mr-3"
          style="width: 200px"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
          <template #selection="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
        </v-select>
      </p>

      <p>
        d) How many significant figures are present in the volume?
        <v-select
          v-model="inputs.question4Value"
          :items="optionsQ4"
          class="d-sm-inline-block ml-3 mr-3"
          style="width: 200px"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
          <template #selection="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-0 mt-6">
        e) What do the number of significant figures indicate about the measurement?
      </p>
      <p>
        <v-select
          v-model="inputs.question5Value"
          :items="optionsQ5"
          class="d-sm-inline-block mr-3"
          style="width: 300px"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
          <template #selection="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
        </v-select>
      </p>
    </v-container>
  </div>
</template>

<script>
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';

export default {
  name: 'UCIrvineQuiz1S1Q1',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        question1Value: null,
        question2Value: null,
        question3Value: null,
        question4Value: null,
        question5Value: null,
      },
      optionsQ1: [
        {
          text: '0.501 g sucrose',
          value: 'answer1',
        },
        {
          text: 'clear liquid',
          value: 'answer2',
        },
        {
          text: 'colorless solution',
          value: 'answer3',
        },
        {
          text: 'microcrystalline',
          value: 'answer4',
        },
        {
          text: '10 mL volumetric flask',
          value: 'answer5',
        },
        {
          text: 'two of these',
          value: 'answer6',
        },
        {
          text: 'all of these',
          value: 'answer7',
        },
      ],
      optionsQ2: [
        {
          text: 'clear solution',
          value: 'answer1',
        },
        {
          text: 'colorless liquid',
          value: 'answer2',
        },
        {
          text: 'white solid',
          value: 'answer3',
        },
        {
          text: 'microcrystalline solid',
          value: 'answer4',
        },
        {
          text: 'all of these',
          value: 'answer5',
        },
      ],
      optionsQ3: [
        {
          text: '1',
          value: 'answer1',
        },
        {
          text: '2',
          value: 'answer2',
        },
        {
          text: '3',
          value: 'answer3',
        },
        {
          text: '4',
          value: 'answer4',
        },
      ],
      optionsQ4: [
        {
          text: '1',
          value: 'answer1',
        },
        {
          text: '2',
          value: 'answer2',
        },
        {
          text: '3',
          value: 'answer3',
        },
        {
          text: '4',
          value: 'answer4',
        },
      ],
      optionsQ5: [
        {
          text: "The measurement's precision.",
          value: 'answer1',
        },
        {
          text: "The measurement's accuracy.",
          value: 'answer2',
        },
        {
          text: "The measurement's closeness to its true value.",
          value: 'answer3',
        },
        {
          text: "The measurement's significance.",
          value: 'answer4',
        },
      ],
    };
  },
};
</script>
