var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('p',{staticStyle:{"font-style":"italic"}},[_vm._v(" When 0.501 g of sucrose (table sugar, white microcrystalline solid) is dissolved in water (clear colorless liquid) to create a solution in a 10 mL volumetric flask, a clear colorless solution is created. ")]),_c('p',[_vm._v("What in the statement above is:")]),_c('p',[_vm._v(" a) quantitative data? "),_c('v-select',{staticClass:"d-sm-inline-block ml-3 mr-3",staticStyle:{"width":"300px"},attrs:{"items":_vm.optionsQ1,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.question1Value),callback:function ($$v) {_vm.$set(_vm.inputs, "question1Value", $$v)},expression:"inputs.question1Value"}})],1),_c('p',[_vm._v(" b) qualitative data?   "),_c('v-select',{staticClass:"d-sm-inline-block ml-3 mr-3",staticStyle:{"width":"300px"},attrs:{"items":_vm.optionsQ2,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.question2Value),callback:function ($$v) {_vm.$set(_vm.inputs, "question2Value", $$v)},expression:"inputs.question2Value"}})],1),_c('p',[_vm._v(" c) How many significant figures are present in the mass?     "),_c('v-select',{staticClass:"d-sm-inline-block ml-3 mr-3",staticStyle:{"width":"200px"},attrs:{"items":_vm.optionsQ3,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.question3Value),callback:function ($$v) {_vm.$set(_vm.inputs, "question3Value", $$v)},expression:"inputs.question3Value"}})],1),_c('p',[_vm._v(" d) How many significant figures are present in the volume? "),_c('v-select',{staticClass:"d-sm-inline-block ml-3 mr-3",staticStyle:{"width":"200px"},attrs:{"items":_vm.optionsQ4,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.question4Value),callback:function ($$v) {_vm.$set(_vm.inputs, "question4Value", $$v)},expression:"inputs.question4Value"}})],1),_c('p',{staticClass:"mb-0 mt-6"},[_vm._v(" e) What do the number of significant figures indicate about the measurement? ")]),_c('p',[_c('v-select',{staticClass:"d-sm-inline-block mr-3",staticStyle:{"width":"300px"},attrs:{"items":_vm.optionsQ5,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.question5Value),callback:function ($$v) {_vm.$set(_vm.inputs, "question5Value", $$v)},expression:"inputs.question5Value"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }